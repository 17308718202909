import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectDetails (Vue, filter, search, sorter, page, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
        .addILike('codigo', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call('parteTrabajoMatsist.selectSubsis', { page, filter: apiFilter, sorter })
    let apiCalls = []
    for (const subsis of resp.data.result.dataset) {
      apiCalls.push({
        name: `selectAccionesPorParteYSubsis_${subsis.idsubsis}`,
        method: 'parteTrabajoMatsist.selectAccionesPorParteYSubsis',
        params: { idparte_trabajo: idparteTrabajo, idsubsis: subsis.idsubsis },
      })
    }
    const subqueryRows = await Vue.$api.batchCall(apiCalls)
    // Añadir subquery a los subsistemas
    for (const [index, subsis] of resp.data.result.dataset.entries()) {
      for (const subquery in subqueryRows.data) {
        if (subquery.endsWith(subsis.idsubsis)) {
          resp.data.result.dataset[index].totalAcciones = subqueryRows.data[subquery].result.dataset
        }
      }
    }
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLparteTrabajoMatsistSubsisRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsubsis', pks)
    const resp = await Vue.$api.call('parteTrabajoMatsist.selectSubsis', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
